<template>
  <div class="vg_wrapper">
    <el-row>
      <el-col :md="24" style="margin: 5px">
        <el-button size="small" type="danger" @click="cancel">取消选择</el-button>
        <el-button class="vg_mr_8" size="small" type="primary" @click="confirmIn">确认选择</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新 </el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <search-table
          ref="multiTable"
          :data="tableData"
          :tableRowKey="`pinv_part_id`"
          :columns="tableProperties"
          v-loading="loadFlag"
          :totalPage="totalPage"
          @selection-change="handleSelectionChange"
          @getTableData="getProdsList"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from '@api/request';
import { tinvAPI } from '@/api/modules/tinv';
import pubPagination from '@/components/common/pubPagination';
import { useCpttMixin } from '@/assets/js/mixin/initMixin';
import SearchTable from '@/components/table/SearchTableV2.vue';

export default {
  name: 'ImportTinv',
  components: {
    SearchTable,
    pubPagination
  },
  mixins: [useCpttMixin],
  data() {
    return {
      tableData: [],
      tableProperties: [
        { prop: 'selection', type: 'selection', labelWidth: '48px' },
        this.$store.state.index,
        {
          prop: 'pinv_invstat',
          label: '开票状态',
          input: true,
          itemType: 'select',
          options: [
            { value: 0, label: '可开票' },
            { value: 1, label: '已开票' },
            { value: 2, label: '部分开票' }
          ],
          widthAuto: true,
          formatter: val => {
            if (val === 0) {
              return '可开票';
            } else if (val === 1) {
              return '已开票';
            } else {
              return '部分开票';
            }
          }
        },
        { prop: 'invoice_no', label: '发票号码', input: true, itemType: 'input', widthAuto: true },
        {
          prop: 'tinv_invtype',
          label: '发票种类',
          input: true,
          itemType: 'select',
          options: [
            { value: 1, label: '增值税发票' },
            { value: 2, label: '普通发票' }
          ],
          widthAuto: true,
          formatter: val => {
            if (val === 1) {
              return '增值税发票';
            } else if (val === 2) {
              return '普通发票';
            }
          }
        },
        {
          prop: 'tinv_type',
          label: '转开票类型',
          input: true,
          itemType: 'select',
          options: [
            { value: 1, label: '开净重' },
            { value: 2, label: '开数量' }
          ],
          widthAuto: true,
          formatter: val => {
            if (val === 1) {
              return '开净重';
            } else if (val === 2) {
              return '开数量';
            }
          }
        },
        { prop: 'tinv_invtotal', label: '转开票金额', input: false, itemType: 'input', widthAuto: true }
      ],
      searchForm: {
        pinv_actu_no: null,
        pinv_invstat: null,
        tinv_no: null,
        pinv_actu_nos: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      contry: [],
      tranNo: 0,
      tinvAttrList: [
        { id: 0, label: '可开票' },
        { id: 1, label: '已开票' }
      ],
      tinv_part_ids: null
    };
  },
  created() {
    // this.initData()
  },
  filters: {
    // 是否主厂
    formatMainShip(row) {
      if (row === 1) {
        return '是';
      } else if (row === 0) {
        return '否';
      }
    }
  },
  methods: {
    initData() {
      this.loadFlag = true;
      this.getProdsList();
    },
    getProdsList() {
      let searchForm = this.$refs.multiTable.searchForm;
      searchForm.tran_no = this.tranNo;
      searchForm.tinv_part_ids = this.tinv_part_ids;
      get(tinvAPI.get_tinvPartByTranNo, searchForm)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.tinv_part_id;
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      // this.searchForm = {
      //   pinv_actu_no: null,
      //   pinv_invstat: null,
      //   tinv_no: null,
      //   pinv_actu_nos: null
      // };
      this.loadFlag = true;
      // this.currentPage = 1;
      this.initData();
      // this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getProdsNow() {
      this.loadFlag = true;
      // this.$refs.pubPagination.currentPage = 1;
      // this.currentPage = 1;
      this.initData();
    },
    // 回显收票类型
    formaInvstat(row) {
      if (row.pinv_invstat === 0) {
        return '可开票';
      } else if (row.pinv_invstat === 1) {
        return '已开票';
      }
    },
    formaInvtype(row) {
      if (row.tinv_invtype === 1) {
        return '增值税发票';
      } else if (row.tinv_invtype === 2) {
        return '普通发票';
      }
    },
    formaType(row) {
      if (row.tinv_type === 1) {
        return '开净重';
      } else if (row.tinv_type === 2) {
        return '开重量';
      }
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getProdsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childChanelTinv');
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childConfirmTinv', this.multiSelection);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.vd_dis_right {
  display: flex;
  justify-content: flex-end;
}
</style>
