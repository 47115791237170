<template>
  <div class="vg_wrapper">
    <el-row>
      <el-col :md="24" style="margin: 5px">
        <el-button size="small" type="danger" @click="cancel">取消选择</el-button>
        <el-button size="small" type="primary" @click="confirmIn">确认选择</el-button>
        <el-button size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i>刷新</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <search-table
          ref="multiTable"
          :data="tableData"
          :tableRowKey="`pinv_part_id`"
          :columns="tableProperties"
          v-loading="loadFlag"
          :totalPage="totalPage"
          @selection-change="handleSelectionChange"
          @getTableData="getProdsList"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from '@api/request';
import { pinvAPI } from '@/api/modules/pinv';
import pubPagination from '@/components/common/pubPagination';
import { useCpttMixin } from '@/assets/js/mixin/initMixin';
import SearchTable from '@/components/table/SearchTableV2.vue';

export default {
  name: 'ImportPinv',
  components: {
    SearchTable,
    pubPagination
  },
  mixins: [useCpttMixin],
  data() {
    return {
      tableData: [],
      searchForm: {
        pinv_actu_nos: null,
        prod_bhsname: null,
        pinv_no: null
      },
      tableProperties: [
        { prop: 'selection', type: 'selection', labelWidth: '48px' },
        this.$store.state.index,
        { prop: 'tran_no', label: '出运发票号', input: false, itemType: 'input', widthAuto: true },
        {
          prop: 'pinv_invstat',
          label: '收票状态',
          input: true,
          itemType: 'select',
          options: [
            { value: 0, label: '未收票' },
            { value: 1, label: '已收票' }
          ],
          widthAuto: true,
          formatter: val => {
            if (val === 0) {
              return '未收票';
            } else if (val === 1) {
              return '已收票';
            }
          }
        },
        { prop: 'invoice_no', label: '发票号码', input: true, itemType: 'input', widthAuto: true },
        {
          prop: 'pinv_type',
          label: '发票类型',
          input: true,
          itemType: 'select',
          options: [
            { value: 1, label: '原材料' },
            { value: 2, label: '加工费' }
          ],
          widthAuto: true,
          formatter: val => {
            if (val === 1) {
              return '原材料';
            } else if (val === 2) {
              return '加工费';
            }
          }
        },
        { prop: 'pinv_part_money', label: '金额', input: false, itemType: 'input', widthAuto: true }
      ],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      prodTypes: [],
      contry: [],
      suppList: [],
      tranNo: 0,
      pinv_part_ids: null
    };
  },
  created() {
    // this.initData()
  },
  filters: {
    // 是否主厂
    formatMainShip(row) {
      if (row === 1) {
        return '是';
      } else if (row === 0) {
        return '否';
      }
    }
  },
  methods: {
    initData() {
      this.getProdsList();
    },
    getProdsList() {
      let searchForm = this.$refs.multiTable.searchForm;
      searchForm.tran_no = this.tranNo;
      searchForm.pinv_part_ids = this.pinv_part_ids;
      get(pinvAPI.get_pinvPartByTranNo, searchForm)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return '' + row.pinv_no + row.pinv_id + row.tinv_no + row.tinv_id + row.pinv_part_id;
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      // this.searchForm = {
      //   pinv_actu_nos: null,
      //   prod_bhsname: null,
      //   pinv_no: null
      // };
      this.loadFlag = true;
      // this.currentPage = 1;
      this.initData();
      // this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getProdsNow() {
      this.loadFlag = true;
      // this.$refs.pubPagination.currentPage = 1;
      // this.currentPage = 1;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getProdsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childChanelPurchaes');
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.multiSelection.forEach(item => {
          console.log(item.pinv_part_money);
          item.pinv_actu_nos = item.pinv_no;
          item.prod_bhsname = item.prod_hsname;
          item.pinv_part_price = item.prod_price;
          item.pinv_part_subtotal = item.pinv_part_money;
          item.pinv_cptt_name = item.cptt_bname;
        });
        this.$emit('childConfirmPurchaes', this.multiSelection);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    },
    formaInvtype(row) {
      if (row.tinv_invtype === 1) {
        return '增值税发票';
      } else if (row.tinv_invtype === 2) {
        return '普通发票';
      }
    },
    formaType(row) {
      if (row.tinv_type === 1) {
        return '开净重';
      } else if (row.tinv_type === 2) {
        return '开重量';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.vd_dis_right {
  display: flex;
  justify-content: flex-end;
}
</style>
