import topic from '../topic';
import { getNoCatch } from '@api/request';
export const statAPI = {
  getStats: topic.statTopic + '/get_stats',
  getSgins: topic.statTopic + '/get_sgins',
  getStatById: topic.statTopic + '/get_stat_by_id',
  addStat: topic.statTopic + '/add_stat',
  editStat: topic.statTopic + '/edit_stat',
  deleteStatByIds: topic.statTopic + '/delete_stat_by_ids',
  exportStat: params => getNoCatch(`${topic.statTopic}/exportStat`, params),
  importInvoice: params => getNoCatch(`${topic.statTopic}/importInvoice`, params)
};
