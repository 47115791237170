<template>
  <div class="vg_wrapper">
    <el-row>
      <el-col :md="24" style="margin: 5px">
        <el-button class="vd_export" size="small" type="danger" @click="cancel">取消选择</el-button>
        <el-button class="vd_export" size="small" type="primary" @click="confirmIn">确认选择</el-button>
        <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新 </el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <search-table
          ref="multiTable"
          :data="tableData"
          :tableRowKey="`key`"
          :columns="tableProperties"
          v-loading="loadFlag"
          :totalPage="totalPage"
          @selection-change="handleSelectionChange"
          @getTableData="getProdsList"
          :needPagination="false"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import pubPagination from '@/components/common/pubPagination';
import { useCpttMixin } from '@/assets/js/mixin/initMixin';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { statAPI } from '@api/modules/stat';

export default {
  name: 'ImportInvoice',
  components: {
    SearchTable,
    pubPagination
  },
  mixins: [useCpttMixin],
  data() {
    return {
      tableData: [],
      tableProperties: [
        { prop: 'selection', type: 'selection', labelWidth: '48px' },
        this.$store.state.index,
        {
          prop: 'type',
          label: '发票类型',
          input: true,
          itemType: 'select',
          widthAuto: true,
          options: [
            { value: 0, label: '转开发票' },
            { value: 1, label: '采购发票' }
          ],
          formatter: val => {
            if (val === 0) {
              return '转开发票';
            } else {
              return '采购发票';
            }
          },
          clearable: true
        },
        { prop: 'pinv_actu_nos', label: '发票号码', input: true, itemType: 'input', widthAuto: true },
        { prop: 'prod_bhsname', label: '开票名称', input: true, itemType: 'input', widthAuto: true },
        { prop: 'pinv_part_num', label: '数量', input: false, itemType: 'input', widthAuto: true },
        { prop: 'prod_unit', label: '数量单位 ', input: false, itemType: 'input', widthAuto: true },
        { prop: 'prod_tnweight', label: '总净重', input: false, itemType: 'input', widthAuto: true },
        { prop: 'pinv_part_subtotal', label: '发票金额', input: false, itemType: 'input', widthAuto: true },
        { prop: 'accept_cptt_name', label: '收票抬头', input: true, itemType: 'input', widthAuto: true },
        { prop: 'invoicing_stamp', label: '开票抬头', input: false, itemType: 'input', widthAuto: true }
      ],
      searchForm: {
        pinv_actu_no: null,
        pinv_invstat: null,
        tinv_no: null,
        pinv_actu_nos: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      contry: [],
      tranNo: 0,
      pinv_part_ids: null,
      tinv_ids: null
    };
  },
  created() {
    // this.initData()
  },
  methods: {
    initData() {
      this.loadFlag = true;
      this.getProdsList();
    },
    //获取数据
    getProdsList() {
      let searchForm = this.$refs.multiTable.searchForm;
      searchForm.tran_no = this.tranNo;
      searchForm.pinv_part_ids = this.pinv_part_ids;
      searchForm.tinv_ids = this.tinv_ids;
      statAPI
        .importInvoice(searchForm)
        .then(({ data }) => {
          this.tableData = data.list;
          setTimeout(() => {
            this.loadFlag = false;
          }, 500);
        })
        .catch(() => {});
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.tinv_part_id;
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.loadFlag = true;
      this.initData();
    },
    // 查询方法
    getProdsNow() {
      this.loadFlag = true;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getProdsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 取消选择
    cancel() {
      this.clear();
      // this.$emit('childChanelInvoice');
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childConfirmInvoice', this.multiSelection);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.vd_dis_right {
  display: flex;
  justify-content: flex-end;
}
</style>
